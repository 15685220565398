.cf-modal-image-container{
    text-align: center;
    padding-top: 50px;
}
.cf-modal-image{
    margin: auto;
    max-width: 100%;
}

.cf-modal-image-header{

}

.cf-modal-carousel .ui5-carousel-item{
    display: block;
}

.ui5-carousel-item-container{
    width: 100%;
}

.ui5-carousel-item{
    margin: auto;
}