.wg-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
    background-color: #333;
    color: white;
}

.wg-footer-icon{
    width: 60px;
    height: 33px;
    padding-right: 20px;
}

.wg-footer ul {
    list-style-type: none;
    padding-left: 0;    
    margin: 0;
}

.wg-footer li {
    display: list-item;
    padding: 10px 0;
}

.wg-footer a {
    color: white;
}
.wg-footer a:hover {
    color: white;
}

.wg-footer .large{
    font-size: 1.375em;
}

@media (min-width: 960px)
{
    .wg-footer {
        flex-direction: row;
        padding: 3em;
    }
}
.wg-footer-finisher>div{
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 3em;
    background-color: black;
    color: #fff;
    padding-left: 4%;
    padding-right: 4%;
}

.wg-footer-finisher a {
    color: white;
}

.wg-footer-finisher a:hover {
    color: white;
}

.wg-footer-finisher>wg-hide-on-desktop{
    flex-direction: column;
}
.wg-footer-finisher>wg-hide-on-mobile{
    flex-direction: row;
}

.wg-footer-finisher-copyright{
    padding-right: 45px;
}

.wg-footer-finisher-prices{
    flex: 1 1 auto;
    padding: 0;
    padding-right: 30px; 
}

.wg-footer-finisher-icon{
    display: flex;
}

.wg-footer-finisher-icon a{
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width: 960px)
{
    .wg-hide-on-desktop{
        display: flex;
    }
    .wg-hide-on-mobile{
        display: none;
    }
}
@media (min-width: 960px)
{
    .wg-hide-on-mobile{
        display: flex;
    }
    .wg-hide-on-desktop{
        display: none;
    }
}

@media (min-width: 960px)
{
    .wg-footer-finisher>div {
        
        flex-direction: row;
        padding-top: 15px;
        padding-bottom: 15px;
        align-items: center;
    }
}
