
.wg-navbar{
    height: 90px;
}

.navMenu {
    position: absolute;
    top: 90px;
    height: calc(100vh - 90px);
    width: 100%;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.navMenu h2 {
    color: #222222;
    font-weight: bold;
    display: flex;
    font-size: 1em;
    line-height: 1.5;
    margin-top: 0;
    border: none;
}



.navMenuUpper {
    box-sizing: content-box;
    max-width: 960px;
    flex-grow: 2;
    flex-direction: column;
    display: flex;
    position: relative;
    margin-left: 1.06383%;
    margin-right: 1.06383%;
    padding: 10px 5% 30px 5%;
    display: flex;
    justify-content: space-between;
    color: #222222;
    transition: all 0.6s ease-out;
}



.navMenuUpper .col {
    flex: 1 0 28%;
    max-width: 50%;
    flex-grow: 1;
}

.navMenuUpper h2 {
    color: #222222;
    font-weight: bold;
    display: flex;
    font-size: 1.1em;
    line-height: 1.5;
    margin-top: 20px;
}

.navMenuUpper ul {
    list-style-type: none;
    padding-left: 0;    
    margin: 0;
}

.navMenuUpper li {
    font-size: 1em;
    margin-bottom: 3px;
    padding-top: 7px;
    padding-bottom: 7px;
    display: list-item;
}
    
.navMenuUpper a, .navMenuUpper a:hover {
    font-size: 1em;
    line-height: 1.5em;
    color: black;
}

.navMenuUpper a:hover{
    text-decoration: none;
}

@media (min-width: 960px)
{
    .navMenuUpper {
        flex-direction: row;
        padding-top: 30px;

    }

    .navMenuUpper h2 {
        margin-top: 0;
    }
}


.navMenuLower{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: calc(2em - 20px);
    padding-bottom: 2.5em;
    background-color: #ce0303;
    -webkit-animation: fadeIn 0.4s ease-out both;
    animation: fadeIn 0.4s ease-out both;
    -webkit-animation-delay: 0.22s;
    animation-delay: 0.22s;
    z-index: 0;
    width: 100%;
    padding-left: 4%;
    padding-right: 4%;
}

.navMenuLower .col {
    flex: 1 0 16%;
    max-width: 100%;
}
@media (min-width: 960px)
{
    .navMenuLower .col {
        max-width: 50%;
    }
}


.navbar-footer-icon {
    margin-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    position: relative;
    top: 0px;
    box-sizing: content-box;
    width: 40px;
    height: 40px;
    color: #ffffff;
    padding-top: 8px;
}

@media (min-width: 960px)
{

}

.navMenuLowerSection{
    display: flex;
}

.navMenuLowerSection h2 {
    font-size: 1.1em;
    line-height: 1.1em;
    color: white;
    margin-top: 20px;
}

.navMenuLowerSection ul {
    list-style-type: none;
    padding-left: 0;    
    margin: 0;
}


.navMenuLowerSection li {
    display: list-item;
    padding: 8px 0;
}

.navMenuLowerSection a, .navMenuLowerSection a:hover {
    font-size: 1em;
    line-height: 1.5em;
    color: white;
}

.navMenuLowerSection a:hover{
    text-decoration: none;
}

@media (min-width: 960px)
{
    .navMenuLower{
        flex-direction: row;
        padding-top: 2em;

    }

    .navMenuUpper h2 {
        margin-top: 0;
    }
}

.navbar-nav{
    font-size: 1.3em;
}

.wg-navbar.navbar-light .navbar-nav .nav-link {
    color: #222222;
}

.navBarButton{
    border: 2px solid #222222;
    border-radius: 4px;
    margin-left: 8px;
    margin-top: auto;
    margin-bottom: auto;
    height: fit-content;
}

.navBarButton.noBorder{
    border: none;
    border-radius: 4px;
    margin-left: 8px;
    margin-top: auto;
    margin-bottom: auto;
    height: fit-content;
}

.navBarButton .phoneNumber{
    font-size: 1rem;
    margin: 0;
}
.navbar-expand-lg .navbar-nav .nav-link.navBarButton {
    padding-right: 1rem;
    padding-left: 1rem;
}    


.navBarButton ui5-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    padding-top: 4px;
}

.custom-navbar{
    background-color:rgb(33, 37, 41);
}

.navbar-brand.cf-logo{
    color: white;
    font-weight: 600;
}
.navbar-brand.cf-logo:hover, .navbar-brand.cf-logo:focus{
    color: white;
}


.container.cf-header, .container-fluid.cf-header, .container-xxl.cf-header, .container-xl.cf-header, .container-lg.cf-header, .container-md.cf-header, .container-sm.cf-header{
    margin-left: 40px;
}