
h1 {
    font-size: calc(26.66667px + 1.77778vw);
    font-family: sans-serif;
    font-weight: bold;
    line-height: 1.1667;
}


h4 {
    font-size: calc(17.33333px + .22222vw);
    font-family: sans-serif;
    line-height: 1.4;
}

@media (min-width: 1200px)
{
    h1 {
        font-size: 48px;
    }
    h4 {
        font-size: 20px;
    }
}

.heroOptionsSelect{
    width: 50%;
}

.heroOptionsSelect {
    --sapFontSize: 1.1em;
    --_ui5_list_item_title_size: 1.1em;
  }

.heroOptionsSelect .ui5-select-label-root{
    padding: 30px;
}

.heroOptionsButton{
    width: 4em;
}

.white{
    color: white;

}

.cf-hero {
    background-image: url('../images/frontpage.webp');
    background-color: #6e6e6e;
    position: relative;
    min-height: 55vh;
    overflow: hidden;
    background-position: 70%;
    background-repeat: no-repeat;
    background-size: cover;
}

.cf-hero-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}
.cf-hero-container {
    margin: 0 auto;
    margin-left: 30px;
    height: 100%;
}
@media (min-width: 940px)
{
    .cf-hero-container {
        max-width: 1200px;
        padding: 0 32px;
    }
}

.cf-hero-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1200px;
    overflow: hidden;
}

.cf-hero-content-left {
    align-items: flex-start;
}
.cf-hero-content-middle {
    justify-content: center;
}

.cf-hero-options{
    display: flex;
    width: 100%;
}

.formContainer{
    max-width: 1078px;
}


.messageStripBottomMarging{
    margin-bottom: 16px;
}


.teaserBannerSplitCMS {
    margin-bottom: 25px;
    overflow: hidden;
}

.imageHolderCMS, .imageHolderCMS img {
    height: 220px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.teaserBannerSplitCMS .newsText, .teaserBannerSplitCMS .richText {
    padding: 10% 5% 2%;
}

.richText h1, .richText h2, .richText h3, .richText h4, .richText h5 {
    font-family: 'BMWTypeNextLatin-Light',sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding-bottom: 2em;
}

.richText p {
    clear: both;
    padding-bottom: 2em;
}

.richText p a, .richText ol li, .richText ul li, .richText p {
    line-height: 22px;
    font-family: 'BMWTypeNextLatin-Regular',sans-serif;
    font-size: .875rem;
    font-weight: normal;
    color: inherit;
    font-weight: 400;
}

.btn-primary, .btnPrimary, .richText.btn-primary-ch>a, .richText.btnPrimary-ch>a {
    display: block;
    float: left;
    height: 44px;
    background-color: #1c69d4;
    color: #fff;
    text-align: center;
    padding: 13px 48px;
    font-size: .8125em;
    font-family: 'BMWTypeNextLatin-Bold',sans-serif;
    text-decoration: none;
    line-height: 160%;
    margin: 0;
    border: none;
    cursor: pointer;
    letter-spacing: .03em;
}

@media only screen and (min-width: 768px)
{

    .contentPageCMS .contentHolder.teaserBannerSplitCMS {
        margin-bottom: 65px;
    }

    .teaserBannerSplitCMS .imageHolderCMS {
        display: block;
        float: left;
        width: 55%;
    }

    .teaserBannerSplitCMS .newsText, .teaserBannerSplitCMS .richText {
        float: right;
        width: 45%;
        padding-top: 36px;
        display: block;
    }
    .contentPageCMS .contentHolder {
        margin: 0 auto;
        max-width: 700px;
    }
}


.contentHolder {
    display: block;
    margin: 0 5%;
    position: relative;
}

footer {
    background-color: #262626;
    color: #fff;
}

.footer {
    padding: 44px 0 0;
}

.widthHolder {
    margin: 0 auto;
    max-width: 1440px;
    height: 100%;
    position: relative;
}

.footerBlock {
    padding: 0 0 44px;
}

footer h2 {
    color: #fff;
    opacity: .4;
    font-size: 1.125rem;
}
.footer ul {
    margin: 0;
    padding: 0 0 20px;
    list-style: none;
}
.footer li {
    padding-bottom: 4px;
}
.footer .addressLine {
    padding-bottom: 20px;
}
.footer a {
    color: #fff;
    font-size: .875rem;
    text-decoration: none;
}
.footer address p.footerPhone {
    color: #fff;
    opacity: .4;
    display: inline;
}
.footer address p {
    font-size: .875rem;
}

p.finisher {
    width: 100%;
    border-top: 1px solid #7d7d7d;
    color: #fff;
    opacity: .4;
    padding: 22px 0;
    font-size: .875rem;
    clear: both;
}
@media only screen and (min-width: 768px)
{

    .leftFooter {
        float: left;
        width: 50%;
        margin-bottom: 44px;
        padding-right: 50px;
    }

    .quickLinksCol {
        float: right;
        width: 50%;
    }
}


.mainCarousel .carousel-cell {
    background-color: transparent;
    margin-left: 20px;
    width: 288px;
}

.defaultCMSFix .carousel-cell {
    height: 532px !important;
    min-height: unset;
}

.teaserOffers02CMS {
    background-color: #fff !important;
    border: 1px solid #e6e6e6;
    display: block;
    padding: 20px;
    overflow: hidden;
    height: 465px;
    position: relative;
}

.imageHolderCMS, .imageHolderCMS img {
    height: 220px;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.teaserOffers02CMS .richText .smallHeader {
    color: #666;
    font-size: .625rem;
    font-family: 'BMWTypeNextLatin-Bold',sans-serif;
    letter-spacing: .08em;
    line-height: .875rem;
    padding-bottom: 2em;
}

.teaserOffers02CMS .richText h3, .teaserOffers02CMS .richText h4, .teaserOffers02CMS .richText h5 {
    font-family: 'BMWTypeNextLatin-Light',sans-serif;
    font-size: 1.125rem;
    padding-bottom: 1.5em;
    line-height: 120%;
}

.teaserOffers02CMS .imageHolderCMS, .teaserOffers02CMS .imageHolderCMS img {
    height: 140px;
}
.richText p a, .richText ol li, .richText ul li, .richText p {
    line-height: 22px;
    font-family: 'BMWTypeNextLatin-Regular',sans-serif;
    font-size: .875rem;
    font-weight: normal;
    color: inherit;
    font-weight: 400;
}
.richText p {
    clear: both;
    padding-bottom: 2em;
}

.teaserOffers02CMS .richText>a {
    margin-top: -20px;
    position: absolute;
    bottom: 5px;
}
.richText.arrowLink-ch>a {
    float: left;
    clear: both;
}
.arrowLink, .richText.arrowLink-ch>a {
    font-family: 'BMWTypeNextLatin-Bold',sans-serif;
    font-size: .8125rem;
    color: #1c69d4;
    line-height: 15px;
    text-decoration: none;
    padding: 15px 36px 15px 0;
    height: 44px;
    display: inline-block;
    letter-spacing: .03em;
}
.mainText {
    padding: 48px 0 0;
}

.mainText h3, .mainText h4, .mainText h5 {
    font-family: 'BMWTypeNextLatin-Light',sans-serif;
    font-size: 1.125rem;
    line-height: 120%;
    padding-bottom: 1.5em;
    text-align: left;
}


@media only screen and (min-width: 768px)
{
    .mainCarousel .carousel-cell {
        width: 326px;
    }

    .defaultCMSFix .carousel-cell {
        height: 540px !important;
    }

    .teaserOffers02CMS .imageHolderCMS, .teaserOffers02CMS .imageHolderCMS img {
        height: 160px;
    }

    .imageHolderCMS, .imageHolderCMS img {
        height: 392px;
    }
    .mainText {
        padding: 70px 0 0;
    }
    .mainText h3, .mainText h4, .mainText h5 {
        font-size: 1.75rem;
        padding-bottom: 1.5em;
        line-height: 120%;
    }
}

.cf-gridContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    align-items: stretch;
    justify-content: stretch;
    margin: 20px;
}

.cf-gridContainerItem {
    align-items: flex-start;
    background-color: #fff;
    box-shadow: 0 0 20px 5px #0000001a;
    color: #000;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem!important;
    width: calc(32% - 25px);
    min-width: auto;
    min-height: auto;
    box-sizing: border-box;
    order: 0;
}

.cf-inner-header {
    font-size: 43px;
}

.cf-inner-center{
    text-align: center;
}

.cf-inner-header-content {
    margin-top: 1.25rem;
    font-size: 1.3rem;
    line-height: 1.5;
}

.cf-inner-button-blue{
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;

    font-size: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.5rem;

    background-color: #007db8;
    border: 1px solid #007db8;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.35rem 0.875rem;
    text-decoration: none;
}


.cf-inner-button-white{
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;

    font-size: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.5rem;

    background-color: initial;
    border: 1px solid #007db8;
    color: #007db8;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.35rem 0.875rem;
    text-decoration: none;
}

.cf-inner-image {
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: left;
    overflow-clip-margin: content-box;
    overflow: clip;
    height: 35rem;
}

.cf-inner-header-container{
    padding-top: 6rem;
}

.cf-inner-header-container-inner{
    padding-top: 8rem;
}

.cf-inner-button-container{
    padding-top: 2rem;
}

.cf-inner-features-container{
    display: flex;
}

.cf-inner-features-header{
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.cf-inner-features-image-container{
    padding-bottom: 2rem;
}

.cf-inner-contact-container {
    flex-direction: row;
    display: flex;
}

.cf-inner-contact-title{
    font-size: 2.2rem;
    line-height: 1.2;
    margin-top: 3.25rem;
    margin-top: 2rem;
    margin-top: 1.25rem;
}


.cf-inner-contact-country{
    font-weight: 400;
    margin-top: 2rem;
    font-size: 1rem;
}

.cf-inner-contact-phone a {
    color: #007db8;
    font-size: 2.0rem;
    line-height: 1.2
}

.cf-inner-contact-verticallinecontainer {
    padding-left: 2rem;
    padding-right: 2rem;
}

.cf-inner-contact-verticalline {
    background: #c6c6c6;
    height: 1px;
    min-width: 1px;
    width: 1px;
    height: auto;
}

.cf-inner-contact-actionbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.cf-inner-contact-actionbox-row {
    align-items: center;
    display: flex;
    margin: 0 auto;
}

.cf-inner-contact-actionbox-img{
    width: 5rem;
}

@media only screen and (min-width: 768px) {
    .cf-inner-contact-imagesection {
        margin-right:1.25rem
    }
}

@media only screen and (min-width: 768px) and (max-width:979px) {
    .cf-inner-contact-imagesection {
        margin-right:2rem
    }
}

@media only screen and (min-width: 1240px) {
    .cf-inner-contact-imagesection {
        margin-right:3.25rem
    }
}


.cf-inner-contact-actionbox-title {
    font-weight: 400;
    color: #007db8;
    cursor: pointer;
    text-decoration: none;
    font-size: 17px;
    line-height: 1.1em;
    margin-bottom: 5px;
}

.cf-inner-contact-actionbox-content {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}
.mainContent{
    overflow: hidden;
}

.cf-modal{
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: white;
}

@media only screen and (min-width: 768px) {
    .cf-modal{
        left: 20%;
        right: 20%;
    }
}

.cf-modal-image-header{
    background: #000;
    color: #fff;
    padding: 35px 75px 35px 50px;
}

.cf-modal-image-header p{
    font-size: 24px;
    margin: 0;
    padding-bottom: 15px;
    text-transform: none;
    font-weight: 400;
}

.cf-modal-carousel .ui5-carousel-item {
    display: block;
} 

 .ui5-tc__tabStrip {
    margin-left: 30px;
}

.cf-breadcrumbs{
    margin-left: 2rem;
}

ul.cf-unorderedlist {
    list-style-type: square;
  }

ul.cf-unorderedlist li::marker {
    font-size: 1.6em;
  }

.cf-rating-section{
    background-color: #ddd;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 60px;
}

.cf-divider-section{
    background: linear-gradient(180deg,#0000 0,#0000000a);
    padding-top: 5.25rem;
}

@font-face
{
    font-family: 'sapicon';
    font-weight: normal;
    font-style: normal;

    src: url('./components/fonts/SAP-icons.ttf');
}

.cf-enlarge-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
}

.cf-enlarge{
    margin-top: 1.25rem;
    align-items: center;
    color: #007db8;
    cursor: pointer;
    display: flex;
    text-decoration: none;
}

.cf-enlarge-icon:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e1f6";
    font-family: sapicon;
    font-size: 24px;
    font-style: normal!important;
    font-variant: normal!important;
    font-weight: 400!important;
    line-height: 24px;
    line-height: 1;
    text-transform: none!important;
}

.cf-enlarge-text{
    margin-left: 0.5rem;
        color: #007db8;
}

.cf-headlineWithLine{
    align-items: center;
    color: #000;
    display: flex;
    gap: 12px;
    min-height: 37px;
}

.cf-headlineWithLine::before{
    align-self: flex-start;
    background-color: #000;
    content: "";
    height: 37px;
    min-width: 4px;
}

.cf-headline{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;   
}

.cf-content{
    padding-top: 5.25rem;
    padding-bottom: 1.25rem;
    padding-left: 25px;
    padding-right: 25px;
}